import React from 'react';
import { X, AlertTriangle } from 'lucide-react';

interface NotificationBannerProps {
  message: string;
  onClose: () => void;
}

export const NotificationBanner: React.FC<NotificationBannerProps> = ({ message, onClose }) => {
  return (
    <div className="bg-red-500/20 border-t border-b border-red-500/20 backdrop-blur-sm">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <AlertTriangle className="w-5 h-5 text-red-400 animate-pulse" />
            <p className="text-sm font-medium text-white">{message}</p>
          </div>
          <button
            onClick={onClose}
            className="text-white/60 hover:text-white transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};