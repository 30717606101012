import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AlertManagement } from '../../components/admin/alerts/AlertManagement';

const AdminAlerts: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Gestión de Alertas - Karealerts Admin</title>
      </Helmet>

      <AlertManagement />
    </>
  );
};

export default AdminAlerts;