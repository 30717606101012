import { useState, useEffect } from 'react';

interface AdminStats {
  activeUsers: number;
  userChange: number;
  activeAlerts: number;
  alertChange: number;
  monitoredRegions: number;
  regionChange: number;
  responseTime: number;
  responseTimeChange: number;
  systemActivity: any;
  alertDistribution: any;
  recentAlerts: Array<{
    id: string;
    title: string;
    severity: 'high' | 'medium' | 'low';
    timestamp: string;
  }>;
  activeUsersList: Array<{
    id: string;
    name: string;
    lastActive: string;
    location: string;
  }>;
}

export const useAdminStats = () => {
  const [stats, setStats] = useState<AdminStats | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Here you would make an API call to get real stats
        // For now, we'll use mock data
        const mockStats: AdminStats = {
          activeUsers: 1234,
          userChange: 12,
          activeAlerts: 23,
          alertChange: -5,
          monitoredRegions: 156,
          regionChange: 8,
          responseTime: 234,
          responseTimeChange: -15,
          systemActivity: {
            labels: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'],
            datasets: [{
              label: 'Actividad',
              data: [65, 59, 80, 81, 56, 55],
              borderColor: 'rgb(59, 130, 246)',
              tension: 0.4
            }]
          },
          alertDistribution: {
            labels: ['Alta', 'Media', 'Baja'],
            datasets: [{
              data: [12, 19, 3],
              backgroundColor: [
                'rgb(239, 68, 68)',
                'rgb(234, 179, 8)',
                'rgb(34, 197, 94)'
              ]
            }]
          },
          recentAlerts: [
            {
              id: '1',
              title: 'Alerta de inundación en Madrid',
              severity: 'high',
              timestamp: '10:30'
            },
            {
              id: '2',
              title: 'Lluvia intensa en Barcelona',
              severity: 'medium',
              timestamp: '10:15'
            }
          ],
          activeUsersList: [
            {
              id: '1',
              name: 'Juan Pérez',
              lastActive: 'Hace 5 min',
              location: 'Madrid'
            },
            {
              id: '2',
              name: 'Ana García',
              lastActive: 'Hace 10 min',
              location: 'Barcelona'
            }
          ]
        };

        setStats(mockStats);
      } catch (error) {
        console.error('Error fetching admin stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 30000); // Update every 30 seconds

    return () => clearInterval(interval);
  }, []);

  return { stats, loading };
};