import React from 'react';
import { Helmet } from 'react-helmet-async';
import { UserManagement } from '../../components/admin/users/UserManagement';

const AdminUsers: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Gestión de Usuarios - Karealerts Admin</title>
      </Helmet>

      <UserManagement />
    </>
  );
};

export default AdminUsers;