import { useState, useEffect } from 'react';
import { User } from '../types';

export const useUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Here you would make an API call
        // For now, using mock data
        const mockUsers: User[] = [
          {
            id: '1',
            name: 'Juan Pérez',
            email: 'juan@example.com',
            role: 'admin',
            location: 'Madrid',
            status: 'active'
          },
          {
            id: '2',
            name: 'Ana García',
            email: 'ana@example.com',
            role: 'moderator',
            location: 'Barcelona',
            status: 'active'
          },
          {
            id: '3',
            name: 'Carlos López',
            email: 'carlos@example.com',
            role: 'user',
            location: 'Valencia',
            status: 'inactive'
          }
        ];

        setUsers(mockUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { users, loading };
};