import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdminHeader } from '../components/admin/AdminHeader';
import { AdminSidebar } from '../components/admin/AdminSidebar';
import { AdminNotifications } from '../components/admin/AdminNotifications';
import { useAdminStore } from '../stores/adminStore';

const AdminLayout: React.FC = () => {
  const isAuthenticated = useAdminStore(state => state.isAuthenticated);

  if (!isAuthenticated) {
    return <Outlet />;
  }

  return (
    <div className="min-h-screen bg-background">
      <AdminHeader />
      <div className="flex">
        <AdminSidebar />
        <main className="flex-1 p-6">
          <Outlet />
        </main>
      </div>
      <AdminNotifications />
    </div>
  );
};

export default AdminLayout;