import { create } from 'zustand';

interface User {
  id: string;
  name: string;
  role: string;
  email: string;
}

interface Notification {
  id: string;
  message: string;
  type: 'info' | 'warning' | 'error';
  timestamp: Date;
}

interface AdminStore {
  user: User | null;
  notifications: Notification[];
  currentSection: string;
  isAuthenticated: boolean;
  setCurrentSection: (section: string) => void;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  logout: () => void;
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  removeNotification: (id: string) => void;
}

export const useAdminStore = create<AdminStore>((set) => ({
  user: null,
  notifications: [],
  currentSection: 'dashboard',
  isAuthenticated: false,
  
  setCurrentSection: (section) => set({ currentSection: section }),
  
  login: async (credentials) => {
    try {
      // API call would go here
      set({
        user: {
          id: '1',
          name: 'Admin User',
          role: 'Administrator',
          email: credentials.email
        },
        isAuthenticated: true
      });
    } catch (error) {
      throw new Error('Authentication failed');
    }
  },
  
  logout: () => set({ user: null, isAuthenticated: false }),
  
  addNotification: (notification) => set((state) => ({
    notifications: [
      ...state.notifications,
      { ...notification, id: Date.now().toString() }
    ]
  })),
  
  removeNotification: (id) => set((state) => ({
    notifications: state.notifications.filter((n) => n.id !== id)
  }))
}));