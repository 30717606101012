import React from 'react';
import { AlertLevel } from '../types';

interface AlertBadgeProps {
  level: AlertLevel;
}

export const AlertBadge: React.FC<AlertBadgeProps> = ({ level }) => {
  const getBgColor = () => {
    switch (level.level) {
      case 'catastrophic': return 'bg-purple-500';
      case 'critical': return 'bg-red-700';
      case 'severe': return 'bg-red-500';
      case 'high': return 'bg-orange-500';
      case 'medium': return 'bg-yellow-500';
      default: return 'bg-blue-500';
    }
  };

  const getAnimation = () => {
    switch (level.level) {
      case 'catastrophic': return 'animate-pulse';
      case 'critical': return 'animate-pulse';
      default: return '';
    }
  };

  return (
    <span
      className={`px-2 py-1 rounded-full text-sm font-semibold ${getBgColor()} ${getAnimation()}`}
    >
      {level.level.charAt(0).toUpperCase() + level.level.slice(1)}
    </span>
  );
};