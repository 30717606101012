import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, Circle } from 'react-leaflet';
import { Navigation2, MapPin } from 'lucide-react';
import { Region } from '../types';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat';
import { useGeolocation } from '../hooks/useGeolocation';
import { LocationPermission } from './LocationPermission';

interface FloodMapProps {
  regions: Region[];
  selectedRegion: Region | null;
  onSelectRegion: (region: Region) => void;
}

// Map updater component
function MapUpdater({ center, zoom }: { center: [number, number]; zoom: number }) {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
}

// Custom location marker
const createLocationMarker = () => {
  return L.divIcon({
    className: 'custom-location-marker',
    html: `
      <div class="relative">
        <div class="absolute inset-0 bg-blue-500 rounded-full opacity-25 animate-ping"></div>
        <div class="relative bg-blue-500 text-white p-2 rounded-full">
          <svg viewBox="0 0 24 24" fill="none" class="w-4 h-4">
            <path d="M12 13.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" fill="currentColor"/>
            <path d="M19.5 10c0 4.5-7.5 11-7.5 11S4.5 14.5 4.5 10a7.5 7.5 0 1115 0z" stroke="currentColor" strokeWidth="2"/>
          </svg>
        </div>
      </div>
    `,
    iconSize: [24, 24],
    iconAnchor: [12, 12]
  });
};

const formatNumber = (value: number): string => {
  if (typeof value !== 'number') return '0';
  return value.toFixed(1).replace(/\.0$/, '');
};

export const FloodMap: React.FC<FloodMapProps> = ({
  regions,
  selectedRegion,
  onSelectRegion
}) => {
  const mapRef = useRef<L.Map | null>(null);
  const [heatmapLayer, setHeatmapLayer] = useState<L.HeatLayer | null>(null);
  const { location, error, loading, permissionDenied, requestPermission } = useGeolocation();
  const [showLocationPrompt, setShowLocationPrompt] = useState(!location && !error);

  useEffect(() => {
    if (!mapRef.current) return;

    // Clear existing layers
    if (heatmapLayer) {
      mapRef.current.removeLayer(heatmapLayer);
    }

    // Create heatmap data
    const heatmapData = regions.map(region => ({
      lat: region.coordinates[1],
      lng: region.coordinates[0],
      intensity: region.riskLevel.level === 'severe' ? 1 :
                region.riskLevel.level === 'high' ? 0.7 :
                region.riskLevel.level === 'medium' ? 0.4 : 0.2
    }));

    // Create new heatmap layer
    const newHeatmapLayer = (L as any).heatLayer(
      heatmapData.map(point => [point.lat, point.lng, point.intensity]),
      {
        radius: 25,
        blur: 15,
        maxZoom: 10,
        gradient: {
          0.2: '#87CEEB',
          0.4: '#FFD700',
          0.6: '#FFA500',
          0.8: '#FF4500',
          1.0: '#FF0000'
        }
      }
    );
    newHeatmapLayer.addTo(mapRef.current);
    setHeatmapLayer(newHeatmapLayer);

  }, [regions]);

  useEffect(() => {
    if (location && mapRef.current) {
      mapRef.current.setView([location.latitude, location.longitude], 13);
    }
  }, [location]);

  const defaultCenter: [number, number] = [40.4168, -3.7038]; // Madrid
  const defaultZoom = 6;

  const createCustomIcon = (region: Region) => {
    return L.divIcon({
      className: 'custom-div-icon',
      html: `
        <div class="p-2 rounded-full border-2 border-white shadow-lg ${
          region.riskLevel.level === 'severe' 
            ? 'bg-red-500' 
            : region.riskLevel.level === 'high'
            ? 'bg-orange-500'
            : 'bg-yellow-500'
        }">
          <svg viewBox="0 0 24 24" class="w-4 h-4 text-white drop-shadow-md">
            <path fill="currentColor" d="M12 2L1 21h22L12 2z"/>
          </svg>
        </div>`,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
    });
  };

  return (
    <div className="relative h-full rounded-xl overflow-hidden">
      {showLocationPrompt && (
        <LocationPermission
          onRequestPermission={() => {
            setShowLocationPrompt(false);
            requestPermission();
          }}
          error={error}
          loading={loading}
        />
      )}

      <MapContainer
        center={location ? [location.latitude, location.longitude] : defaultCenter}
        zoom={location ? 13 : defaultZoom}
        style={{ width: '100%', height: '100%' }}
        className="z-0"
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />

        {location && (
          <>
            <Marker
              position={[location.latitude, location.longitude]}
              icon={createLocationMarker()}
            >
              <Popup>
                <div className="text-center">
                  <p className="font-medium">Tu ubicación actual</p>
                  <p className="text-sm text-gray-500">
                    Precisión: {Math.round(location.accuracy)}m
                  </p>
                </div>
              </Popup>
            </Marker>
            <Circle
              center={[location.latitude, location.longitude]}
              radius={location.accuracy}
              pathOptions={{ color: '#3b82f6', fillColor: '#3b82f6', fillOpacity: 0.1 }}
            />
          </>
        )}

        {regions.map((region) => (
          <Marker
            key={region.name}
            position={[region.coordinates[1], region.coordinates[0]]}
            icon={createCustomIcon(region)}
            eventHandlers={{
              click: () => onSelectRegion(region),
            }}
          >
            <Popup>
              <div className="p-4 bg-gray-900/95 backdrop-blur-md rounded-lg border border-white/10">
                <h3 className="font-bold text-lg text-white mb-2">{region.name}</h3>
                <div className="space-y-2 text-sm text-gray-300">
                  <p>Temperatura: {formatNumber(region.weather?.temperature)}°C</p>
                  <p>Lluvia: {formatNumber(region.rainfall)} mm/h</p>
                  <p>Viento: {Math.round(region.weather?.windSpeed || 0)} km/h</p>
                  <p>Humedad: {Math.round(region.weather?.humidity || 0)}%</p>
                  <p>Nivel del río: {formatNumber(region.riverLevel)}m</p>
                  <div className={`mt-2 px-2 py-1 rounded-full text-center text-white ${
                    region.riskLevel.level === 'severe' 
                      ? 'bg-red-500 animate-pulse' 
                      : region.riskLevel.level === 'high'
                      ? 'bg-orange-500'
                      : 'bg-yellow-500'
                  }`}>
                    {region.riskLevel.level.toUpperCase()}
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}

        {selectedRegion && (
          <MapUpdater
            center={[selectedRegion.coordinates[1], selectedRegion.coordinates[0]]}
            zoom={10}
          />
        )}
      </MapContainer>

      {/* Custom controls */}
      <div className="absolute top-4 right-4 z-[1000] space-y-2">
        <button
          onClick={() => mapRef.current?.setView(defaultCenter, defaultZoom)}
          className="p-2 bg-white/10 hover:bg-white/20 rounded-lg backdrop-blur-md transition-colors"
          title="Center map"
        >
          <Navigation2 className="w-5 h-5 text-white" />
        </button>
        {location && (
          <button
            onClick={() => mapRef.current?.setView([location.latitude, location.longitude], 13)}
            className="p-2 bg-white/10 hover:bg-white/20 rounded-lg backdrop-blur-md transition-colors"
            title="Mi ubicación"
          >
            <MapPin className="w-5 h-5 text-white" />
          </button>
        )}
      </div>

      {/* Legend */}
      <div className="absolute bottom-4 right-4 z-[1000] bg-black/50 backdrop-blur-md p-4 rounded-lg">
        <h4 className="text-sm font-semibold text-white mb-2">Niveles de Riesgo</h4>
        <div className="space-y-2">
          {['Severe', 'High', 'Medium'].map((level, index) => (
            <div key={level} className="flex items-center space-x-2">
              <div className={`w-3 h-3 rounded-full ${
                index === 0 ? 'bg-red-500' :
                index === 1 ? 'bg-orange-500' :
                'bg-yellow-500'
              }`} />
              <span className="text-xs text-white">{level}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};