import React from 'react';
import { X } from 'lucide-react';

export const UserFilters: React.FC = () => {
  return (
    <div className="glass-card p-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="font-medium">Filtros</h3>
        <button className="text-gray-400 hover:text-white">
          <X className="w-4 h-4" />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm text-gray-400 mb-2">Rol</label>
          <select className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 text-white">
            <option value="">Todos los roles</option>
            <option value="admin">Administrador</option>
            <option value="moderator">Moderador</option>
            <option value="user">Usuario</option>
          </select>
        </div>

        <div>
          <label className="block text-sm text-gray-400 mb-2">Estado</label>
          <select className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 text-white">
            <option value="">Todos los estados</option>
            <option value="active">Activo</option>
            <option value="inactive">Inactivo</option>
            <option value="suspended">Suspendido</option>
          </select>
        </div>

        <div>
          <label className="block text-sm text-gray-400 mb-2">Ubicación</label>
          <select className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 text-white">
            <option value="">Todas las ubicaciones</option>
            <option value="madrid">Madrid</option>
            <option value="barcelona">Barcelona</option>
            <option value="valencia">Valencia</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <button className="px-4 py-2 text-gray-400 hover:text-white transition-colors">
          Limpiar
        </button>
        <button className="glass-button">
          Aplicar Filtros
        </button>
      </div>
    </div>
  );
};