import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AlertsList } from '../components/AlertsList';
import { StatsGrid } from '../components/StatsGrid';
import { EmergencyHelp } from '../components/EmergencyHelp';
import { ReportIncident } from '../components/ReportIncident';
import { WeatherWidget } from '../components/WeatherWidget';
import { NotificationBanner } from '../components/NotificationBanner';
import { SearchBar } from '../components/SearchBar';
import { ResourcesPanel } from '../components/ResourcesPanel';
import { EmailSubscription } from '../components/EmailSubscription';
import { FloodMap } from '../components/Map';
import { TransportStatus } from '../components/TransportStatus';
import { SocialShare } from '../components/SocialShare';
import { getRegionsData } from '../services/mockData';

const HomePage: React.FC = () => {
  const [showNotification, setShowNotification] = useState(true);
  const [showResources, setShowResources] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const regions = getRegionsData();

  return (
    <>
      <Helmet>
        <title>Karealerts - Alertas Meteorológicas y Estado del Transporte en Tiempo Real</title>
        <meta name="description" content="Monitoriza en tiempo real el estado del tiempo y transporte público en tu ciudad. Alertas instantáneas de meteorología, inundaciones y servicios públicos." />
      </Helmet>

      {showNotification && (
        <NotificationBanner 
          message="⚠️ Múltiples alertas activas: Inundaciones, Transporte y Calidad del Aire" 
          onClose={() => setShowNotification(false)}
        />
      )}

      <main className="container mx-auto px-4 py-6">
        <div className="mb-6">
          <SearchBar 
            onSearch={() => {}}
            onLocationSelect={() => {}}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
          <div className="lg:col-span-2">
            <div className="glass-card p-6 h-[400px]">
              <FloodMap
                regions={regions}
                selectedRegion={selectedRegion}
                onSelectRegion={setSelectedRegion}
              />
            </div>
          </div>
          <div className="space-y-6">
            <WeatherWidget />
            <SocialShare />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 space-y-6">
            <StatsGrid regions={regions} />
            <TransportStatus 
              status={selectedRegion?.transportStatus}
              city={selectedRegion?.name}
            />
          </div>
          <div className="space-y-6">
            <div className="glass-card p-6">
              <h2 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent mb-4">
                Alertas Activas
              </h2>
              <AlertsList alerts={selectedRegion?.alerts || []} />
            </div>
            <EmailSubscription />
          </div>
        </div>
      </main>

      <EmergencyHelp />
      <ReportIncident onSubmit={() => {}} />
      
      {showResources && (
        <ResourcesPanel onClose={() => setShowResources(false)} />
      )}
    </>
  );
};

export default HomePage;