import React from 'react';
import { Twitter, Facebook, Share2, Link } from 'lucide-react';
import { TwitterShareButton, FacebookShareButton } from 'react-share';
import toast from 'react-hot-toast';

export const SocialShare: React.FC = () => {
  const shareUrl = 'https://karealerts.com';
  const title = 'Monitoriza en tiempo real el estado del tiempo y transporte público en tu ciudad 🌦️🚇';

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success('¡Enlace copiado!');
    } catch (err) {
      toast.error('Error al copiar el enlace');
    }
  };

  return (
    <div className="glass-card p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
          Comparte Karealerts
        </h3>
        <Share2 className="w-6 h-6 text-blue-400" />
      </div>

      <div className="flex justify-center space-x-4 mb-6">
        <TwitterShareButton url={shareUrl} title={title}>
          <div className="p-3 bg-[#1DA1F2]/10 hover:bg-[#1DA1F2]/20 rounded-lg transition-colors cursor-pointer">
            <Twitter className="w-6 h-6 text-[#1DA1F2]" />
          </div>
        </TwitterShareButton>

        <FacebookShareButton url={shareUrl} quote={title}>
          <div className="p-3 bg-[#4267B2]/10 hover:bg-[#4267B2]/20 rounded-lg transition-colors cursor-pointer">
            <Facebook className="w-6 h-6 text-[#4267B2]" />
          </div>
        </FacebookShareButton>

        <button 
          onClick={copyToClipboard}
          className="p-3 bg-white/10 hover:bg-white/20 rounded-lg transition-colors cursor-pointer"
        >
          <Link className="w-6 h-6 text-gray-400" />
        </button>
      </div>

      <div className="border-t border-white/10 pt-6">
        <div className="text-center text-sm text-gray-400">
          <p>¡Ayúdanos a mantener a todos informados!</p>
          <p className="mt-2">Comparte esta herramienta con tus amigos y familiares.</p>
        </div>
      </div>
    </div>
  );
};