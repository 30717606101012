import React from 'react';
import { MapPin, AlertTriangle, Settings } from 'lucide-react';
import { motion } from 'framer-motion';

interface LocationPermissionProps {
  onRequestPermission: () => void;
  error: string | null;
  loading?: boolean;
}

export const LocationPermission: React.FC<LocationPermissionProps> = ({
  onRequestPermission,
  error,
  loading = false
}) => {
  const openSettings = () => {
    if (navigator.userAgent.includes('Chrome')) {
      window.open('chrome://settings/content/location');
    } else if (navigator.userAgent.includes('Firefox')) {
      window.open('about:preferences#privacy');
    } else {
      window.open('about:preferences');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="absolute inset-0 bg-gray-900/90 backdrop-blur-sm rounded-lg flex items-center justify-center p-6 z-10"
    >
      <div className="glass-card p-6 max-w-md w-full text-center">
        {error ? (
          <>
            <AlertTriangle className="w-12 h-12 text-red-400 mx-auto mb-4" />
            <h3 className="text-xl font-bold text-white mb-2">
              Error de ubicación
            </h3>
            <p className="text-gray-400 mb-6">{error}</p>
            <div className="space-y-3">
              <button
                onClick={onRequestPermission}
                disabled={loading}
                className="w-full glass-button disabled:opacity-50"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                  </div>
                ) : (
                  'Reintentar'
                )}
              </button>
              <button
                onClick={openSettings}
                className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-white/5 rounded-lg hover:bg-white/10 transition-colors"
              >
                <Settings className="w-4 h-4" />
                <span>Abrir Configuración</span>
              </button>
            </div>
          </>
        ) : (
          <>
            <MapPin className="w-12 h-12 text-blue-400 mx-auto mb-4" />
            <h3 className="text-xl font-bold text-white mb-2">
              Permitir acceso a la ubicación
            </h3>
            <p className="text-gray-400 mb-6">
              Para mostrarte alertas relevantes en tu área, necesitamos acceder a tu ubicación.
              Tus datos de ubicación son privados y solo se usan para mostrar alertas cercanas.
            </p>
            <button
              onClick={onRequestPermission}
              disabled={loading}
              className="w-full glass-button disabled:opacity-50"
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                </div>
              ) : (
                'Permitir ubicación'
              )}
            </button>
          </>
        )}
      </div>
    </motion.div>
  );
};