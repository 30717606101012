import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { 
  AlertTriangle, Cloud, Train, Car, Construction,
  Flame, Wind, Thermometer, Waves, Mountain, Shield,
  Zap, Bug, Globe, Activity, RadioTower
} from 'lucide-react';
import { Alert, AlertType } from '../types';
import { AlertBadge } from './AlertBadge';

interface AlertsListProps {
  alerts: Alert[];
}

const AlertIcon = ({ type }: { type: AlertType }) => {
  const icons = {
    flood: <Waves className="w-5 h-5" />,
    rain: <Cloud className="w-5 h-5" />,
    transport: <Train className="w-5 h-5" />,
    road: <Car className="w-5 h-5" />,
    emergency: <AlertTriangle className="w-5 h-5" />,
    infrastructure: <Construction className="w-5 h-5" />,
    weather: <Cloud className="w-5 h-5" />,
    earthquake: <Activity className="w-5 h-5" />,
    fire: <Flame className="w-5 h-5" />,
    landslide: <Mountain className="w-5 h-5" />,
    pollution: <Wind className="w-5 h-5" />,
    health: <Bug className="w-5 h-5" />,
    security: <Shield className="w-5 h-5" />,
    tsunami: <Waves className="w-5 h-5" />,
    volcano: <Mountain className="w-5 h-5" />,
    storm: <Cloud className="w-5 h-5" />,
    heatwave: <Thermometer className="w-5 h-5" />,
    coldwave: <Thermometer className="w-5 h-5" />,
    avalanche: <Mountain className="w-5 h-5" />,
    terrorist: <Shield className="w-5 h-5" />,
    chemical: <RadioTower className="w-5 h-5" />,
    nuclear: <RadioTower className="w-5 h-5" />,
    biological: <Bug className="w-5 h-5" />,
    cyber: <Globe className="w-5 h-5" />
  };
  return icons[type] || <AlertTriangle className="w-5 h-5" />;
};

export const AlertsList: React.FC<AlertsListProps> = ({ alerts }) => {
  return (
    <div className="space-y-4">
      {alerts.map((alert) => (
        <div
          key={alert.id}
          className={`glass-card p-4 ${
            alert.level.level === 'severe' ? 'animate-pulse' : ''
          }`}
        >
          <div className="flex items-start space-x-3">
            <div className={`p-2 rounded-lg ${alert.level.color}`}>
              <AlertIcon type={alert.type} />
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-semibold text-white">{alert.message}</h4>
                  <p className="text-sm text-gray-400">
                    {alert.region} · {formatDistanceToNow(alert.timestamp, { locale: es, addSuffix: true })}
                  </p>
                </div>
                <AlertBadge level={alert.level} />
              </div>

              {alert.details?.recommendations && (
                <div className="mt-3 space-y-2">
                  <h5 className="text-sm font-medium text-gray-300">Recomendaciones:</h5>
                  <ul className="list-disc list-inside text-sm space-y-1 text-gray-400">
                    {alert.details.recommendations.map((rec, i) => (
                      <li key={i}>{rec}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};