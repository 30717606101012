import React from 'react';
import { CloudRainWind, Bell, Menu, X, Shield, Settings, HelpCircle, Zap, MapPin, Radio, LineChart, Crown } from 'lucide-react';

interface HeaderProps {
  onShowResources: () => void;
  onShowMonitoring: () => void;
  onShowAdmin: () => void;
}

export const Header: React.FC<HeaderProps> = ({ 
  onShowResources, 
  onShowMonitoring,
  onShowAdmin 
}) => {
  return (
    <header className="relative bg-gradient-to-r from-blue-900/90 to-purple-900/90 backdrop-blur-xl border-b border-white/10">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-200" />
              <div className="relative bg-gradient-to-r from-blue-900 to-purple-900 p-3 rounded-lg ring-1 ring-white/10 hover:ring-white/20 transition-all duration-300">
                <CloudRainWind className="w-8 h-8 text-blue-400" />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent">
                  Karealerts
                </span>
              </h1>
              <p className="text-sm text-gray-400">Sistema de Alertas Inteligente</p>
            </div>
          </div>
          
          <nav className="hidden md:flex items-center space-x-6">
            <button 
              onClick={onShowMonitoring}
              className="text-gray-300 hover:text-white transition-colors flex items-center space-x-2"
            >
              <LineChart className="w-5 h-5" />
              <span>Monitorización</span>
            </button>
            <button
              onClick={onShowResources}
              className="text-gray-300 hover:text-white transition-colors flex items-center space-x-2"
            >
              <Shield className="w-5 h-5" />
              <span>Recursos</span>
            </button>
            <button
              onClick={onShowAdmin}
              className="text-gray-300 hover:text-white transition-colors flex items-center space-x-2"
            >
              <Settings className="w-5 h-5" />
              <span>Admin</span>
            </button>
            <a
              href="/premium"
              className="flex items-center space-x-2 bg-gradient-to-r from-yellow-400 to-yellow-600 text-black font-medium px-4 py-2 rounded-lg hover:from-yellow-500 hover:to-yellow-700 transition-all"
            >
              <Crown className="w-5 h-5" />
              <span>Premium</span>
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};