import React from 'react';
import { ArrowUp, ArrowDown } from 'lucide-react';

interface AdminMetricCardProps {
  title: string;
  value: string | number;
  change: number;
  icon: React.ReactNode;
}

export const AdminMetricCard: React.FC<AdminMetricCardProps> = ({
  title,
  value,
  change,
  icon
}) => {
  const isPositive = change >= 0;

  return (
    <div className="glass-card p-6">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-sm text-gray-400 mb-1">{title}</h3>
          <div className="text-2xl font-bold">{value}</div>
        </div>
        <div className="p-3 rounded-lg bg-white/5">
          {icon}
        </div>
      </div>
      
      <div className="mt-4 flex items-center space-x-2">
        <div className={`flex items-center ${
          isPositive ? 'text-green-400' : 'text-red-400'
        }`}>
          {isPositive ? (
            <ArrowUp className="w-4 h-4" />
          ) : (
            <ArrowDown className="w-4 h-4" />
          )}
          <span className="text-sm font-medium">
            {Math.abs(change)}%
          </span>
        </div>
        <span className="text-sm text-gray-400">vs. último periodo</span>
      </div>
    </div>
  );
};