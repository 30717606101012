import React, { useState } from 'react';
import { Phone, MessageSquare, Loader2, Send } from 'lucide-react';

export const EmergencyHelp: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState<{text: string, isUser: boolean}[]>([]);
  const [loading, setLoading] = useState(false);

  const emergencyNumbers = [
    { name: '112 - Emergencias', number: '112' },
    { name: 'Protección Civil', number: '915 373 100' },
    { name: 'Bomberos', number: '080' },
  ];

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) return;

    const userMessage = message;
    setChat(prev => [...prev, { text: userMessage, isUser: true }]);
    setMessage('');
    setLoading(true);

    // Simulate AI response
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    const response = "Un agente de emergencias ha sido notificado y está revisando tu situación. Por favor, mantente en un lugar seguro. ¿Hay algo más en lo que pueda ayudarte?";
    setChat(prev => [...prev, { text: response, isUser: false }]);
    setLoading(false);
  };

  return (
    <div className="fixed bottom-6 left-6 z-50">
      {!isOpen ? (
        <button
          onClick={() => setIsOpen(true)}
          className="glass-button bg-red-500 hover:bg-red-600 flex items-center space-x-2"
        >
          <Phone className="w-5 h-5" />
          <span>Ayuda de Emergencia</span>
        </button>
      ) : (
        <div className="glass-card w-96">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold text-red-400">
                Ayuda de Emergencia
              </h3>
              <button
                onClick={() => {
                  setIsOpen(false);
                  setIsChatOpen(false);
                }}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>

            {!isChatOpen ? (
              <div className="space-y-4">
                <div className="space-y-3">
                  {emergencyNumbers.map((contact) => (
                    <a
                      key={contact.number}
                      href={`tel:${contact.number}`}
                      className="flex items-center justify-between p-3 bg-white/5 rounded-xl hover:bg-white/10 transition-colors"
                    >
                      <span className="text-white">{contact.name}</span>
                      <span className="text-red-400 font-semibold">{contact.number}</span>
                    </a>
                  ))}
                </div>

                <div className="border-t border-white/10 pt-4">
                  <button
                    onClick={() => setIsChatOpen(true)}
                    className="w-full flex items-center justify-center space-x-2 p-3 bg-white/5 rounded-xl hover:bg-white/10 transition-colors"
                  >
                    <MessageSquare className="w-5 h-5 text-blue-400" />
                    <span>Chatear con Emergencias</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <div className="h-80 overflow-y-auto custom-scrollbar space-y-3">
                  {chat.map((msg, i) => (
                    <div
                      key={i}
                      className={`p-3 rounded-xl ${
                        msg.isUser
                          ? 'bg-blue-500/20 ml-8'
                          : 'bg-white/5 mr-8'
                      }`}
                    >
                      {msg.text}
                    </div>
                  ))}
                  {loading && (
                    <div className="flex items-center space-x-2 text-gray-400 p-3">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Respondiendo...</span>
                    </div>
                  )}
                </div>

                <form onSubmit={handleSendMessage} className="flex space-x-2">
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Escribe tu mensaje..."
                    className="flex-1 bg-white/5 border border-white/10 rounded-xl px-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500/50"
                  />
                  <button
                    type="submit"
                    disabled={loading || !message.trim()}
                    className="glass-button disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Send className="w-4 h-4" />
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};