import { Region, Alert, TransportStatus } from '../types';

// Coordenadas de principales ciudades españolas
const CITIES = [
  { name: 'Madrid', coords: [40.4168, -3.7038] },
  { name: 'Barcelona', coords: [41.3851, 2.1734] },
  { name: 'Valencia', coords: [39.4699, -0.3763] },
  { name: 'Sevilla', coords: [37.3891, -5.9845] },
  { name: 'Zaragoza', coords: [41.6488, -0.8891] },
  { name: 'Málaga', coords: [36.7213, -4.4217] },
  { name: 'Murcia', coords: [37.9922, -1.1307] },
  { name: 'Palma', coords: [39.5696, 2.6502] },
  { name: 'Bilbao', coords: [43.2630, -2.9350] },
  { name: 'Alicante', coords: [38.3452, -0.4815] }
];

// Generador de datos meteorológicos realistas
const generateWeatherData = () => {
  const season = new Date().getMonth();
  const isWinter = season >= 11 || season <= 1;
  const isSpring = season >= 2 && season <= 4;
  const isSummer = season >= 5 && season <= 7;
  const isAutumn = season >= 8 && season <= 10;

  const baseTemp = isSummer ? 28 : isWinter ? 8 : 18;
  const baseRain = isSpring || isAutumn ? 30 : isSummer ? 10 : 20;

  return {
    temperature: baseTemp + (Math.random() * 10 - 5),
    rainfall: Math.max(0, baseRain + (Math.random() * 20 - 10)),
    humidity: Math.floor(Math.random() * 30 + 50),
    windSpeed: Math.floor(Math.random() * 30 + 5),
    pressure: Math.floor(Math.random() * 30 + 1000)
  };
};

// Generador de estados de transporte
const generateTransportStatus = (city: string): TransportStatus => {
  const lines = ['L1', 'L2', 'L3', 'L4', 'L5'].map(line => ({
    name: line,
    status: Math.random() > 0.8 ? 
      (Math.random() > 0.5 ? 'delayed' : 'suspended') : 
      'normal',
    message: Math.random() > 0.8 ? 
      'Retrasos por incidencia técnica' : 
      undefined
  }));

  return {
    metro: lines,
    bus: [
      {
        name: 'C1',
        status: Math.random() > 0.9 ? 'delayed' : 'normal',
        message: 'Desvíos por obras'
      }
    ]
  };
};

// Generador de alertas
const generateAlerts = (city: string, weather: any): Alert[] => {
  const alerts: Alert[] = [];

  // Alerta por lluvia
  if (weather.rainfall > 30) {
    alerts.push({
      id: `rain-${city}-${Date.now()}`,
      type: 'weather',
      title: 'Alerta por Lluvias Intensas',
      message: `Precipitaciones intensas en ${city}`,
      region: city,
      timestamp: new Date(),
      level: { 
        level: weather.rainfall > 50 ? 'severe' : 'high',
        color: weather.rainfall > 50 ? 'bg-red-500' : 'bg-orange-500'
      },
      details: {
        recommendations: [
          'Evitar desplazamientos innecesarios',
          'Mantenerse alejado de zonas inundables'
        ]
      }
    });
  }

  // Alerta por viento
  if (weather.windSpeed > 50) {
    alerts.push({
      id: `wind-${city}-${Date.now()}`,
      type: 'weather',
      title: 'Alerta por Vientos Fuertes',
      message: `Vientos de ${weather.windSpeed}km/h en ${city}`,
      region: city,
      timestamp: new Date(),
      level: { 
        level: weather.windSpeed > 70 ? 'severe' : 'high',
        color: weather.windSpeed > 70 ? 'bg-red-500' : 'bg-orange-500'
      }
    });
  }

  // Alertas de transporte aleatorias
  if (Math.random() > 0.7) {
    alerts.push({
      id: `transport-${city}-${Date.now()}`,
      type: 'transport',
      title: 'Incidencia en Metro',
      message: 'Retrasos en línea principal',
      region: city,
      timestamp: new Date(),
      level: { level: 'medium', color: 'bg-yellow-500' }
    });
  }

  return alerts;
};

// Generador de datos de regiones
export const generateRegionsData = (): Region[] => {
  return CITIES.map(city => {
    const weather = generateWeatherData();
    const alerts = generateAlerts(city.name, weather);
    const transport = generateTransportStatus(city.name);

    // Calcular nivel de riesgo basado en condiciones
    let riskLevel = { level: 'medium' as const, color: 'bg-yellow-500' as const };
    if (weather.rainfall > 50 || weather.windSpeed > 70) {
      riskLevel = { level: 'severe', color: 'bg-red-500' };
    } else if (weather.rainfall > 30 || weather.windSpeed > 50) {
      riskLevel = { level: 'high', color: 'bg-orange-500' };
    }

    return {
      name: city.name,
      coordinates: [city.coords[1], city.coords[0]], // [lon, lat] format for GeoJSON
      rainfall: weather.rainfall,
      riverLevel: 2 + (weather.rainfall / 50), // Simular nivel del río basado en lluvia
      riskLevel,
      transportStatus: transport,
      alerts,
      weather: {
        temperature: weather.temperature,
        humidity: weather.humidity,
        windSpeed: weather.windSpeed,
        pressure: weather.pressure
      }
    };
  });
};

// Actualizar datos cada 5 minutos
let regionsData = generateRegionsData();
setInterval(() => {
  regionsData = generateRegionsData();
}, 5 * 60 * 1000);

export const getRegionsData = () => regionsData;