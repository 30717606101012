import { useState, useEffect } from 'react';
import { AdminAlert } from '../types/admin';

export const useAlerts = () => {
  const [alerts, setAlerts] = useState<AdminAlert[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        // Here you would make an API call
        // For now, using mock data
        const mockAlerts: AdminAlert[] = [
          {
            id: '1',
            title: 'Alerta de inundación',
            description: 'Riesgo de inundación en zona centro',
            type: 'flood',
            severity: 'high',
            location: 'Madrid Centro',
            status: 'active',
            timestamp: new Date().toISOString()
          },
          {
            id: '2',
            title: 'Retrasos en Metro',
            description: 'Retrasos en Línea 1 por incidencia técnica',
            type: 'transport',
            severity: 'medium',
            location: 'Línea 1 Metro',
            status: 'active',
            timestamp: new Date().toISOString()
          }
        ];

        setAlerts(mockAlerts);
      } catch (error) {
        console.error('Error fetching alerts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAlerts();
  }, []);

  return { alerts, loading };
};