import React from 'react';
import { Train, Bus, AlertTriangle, Clock, MapPin } from 'lucide-react';
import { TransportStatus as TransportStatusType } from '../types';
import { motion } from 'framer-motion';

interface TransportStatusProps {
  status?: TransportStatusType;
  city?: string;
}

export const TransportStatus: React.FC<TransportStatusProps> = ({ status, city = 'Madrid' }) => {
  if (!status) return null;

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'normal': return 'text-green-400 bg-green-500/20';
      case 'delayed': return 'text-yellow-400 bg-yellow-500/20';
      case 'suspended': return 'text-red-400 bg-red-500/20';
      case 'limited': return 'text-orange-400 bg-orange-500/20';
      default: return 'text-gray-400 bg-gray-500/20';
    }
  };

  const getStatusIcon = (type: string) => {
    switch (type) {
      case 'metro':
      case 'train':
        return <Train className="w-5 h-5" />;
      case 'bus':
        return <Bus className="w-5 h-5" />;
      default:
        return null;
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'normal': return 'Funcionamiento normal';
      case 'delayed': return 'Retrasos';
      case 'suspended': return 'Servicio suspendido';
      case 'limited': return 'Servicio limitado';
      default: return 'Estado desconocido';
    }
  };

  return (
    <div className="glass-card p-6">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Estado del Transporte
          </h3>
          <p className="text-sm text-gray-400">{city}</p>
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="w-5 h-5 text-gray-400" />
          <span className="text-sm text-gray-400">
            Actualizado {new Date().toLocaleTimeString()}
          </span>
        </div>
      </div>

      <div className="space-y-6">
        {Object.entries(status).map(([type, services]) => (
          <div key={type} className="space-y-3">
            <div className="flex items-center space-x-2 text-gray-400">
              {getStatusIcon(type)}
              <h4 className="font-medium capitalize">{type}</h4>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {services.map((service) => (
                <motion.div
                  key={service.name}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`${getStatusColor(service.status)} rounded-lg p-4`}
                >
                  <div className="flex justify-between items-start mb-2">
                    <span className="font-medium">{service.name}</span>
                    <span className="px-2 py-1 rounded-full text-xs bg-white/10">
                      {getStatusText(service.status)}
                    </span>
                  </div>

                  {service.message && (
                    <div className="flex items-start space-x-2 text-sm mt-2">
                      <AlertTriangle className="w-4 h-4 shrink-0 mt-0.5" />
                      <p>{service.message}</p>
                    </div>
                  )}

                  {service.alternativeRoutes && service.alternativeRoutes.length > 0 && (
                    <div className="mt-3">
                      <div className="flex items-center space-x-2 text-sm mb-2">
                        <MapPin className="w-4 h-4" />
                        <span>Rutas alternativas:</span>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {service.alternativeRoutes.map((route, index) => (
                          <span
                            key={index}
                            className="px-2 py-1 rounded-full text-xs bg-white/10"
                          >
                            {route}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};