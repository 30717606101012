import React from 'react';
import { Cloud, CloudRain, Wind, Droplets } from 'lucide-react';

export const WeatherWidget: React.FC = () => {
  return (
    <div className="glass-card p-6">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
            Pronóstico
          </h3>
          <p className="text-sm text-gray-400">Madrid, España</p>
        </div>
        <div className="text-right">
          <div className="text-3xl font-bold">18°C</div>
          <p className="text-sm text-gray-400">Lluvia ligera</p>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <div className="text-center">
          <div className="bg-white/5 p-3 rounded-lg mb-2">
            <CloudRain className="w-6 h-6 text-blue-400 mx-auto" />
          </div>
          <div className="text-sm font-medium">85%</div>
          <div className="text-xs text-gray-400">Lluvia</div>
        </div>
        <div className="text-center">
          <div className="bg-white/5 p-3 rounded-lg mb-2">
            <Wind className="w-6 h-6 text-green-400 mx-auto" />
          </div>
          <div className="text-sm font-medium">12km/h</div>
          <div className="text-xs text-gray-400">Viento</div>
        </div>
        <div className="text-center">
          <div className="bg-white/5 p-3 rounded-lg mb-2">
            <Droplets className="w-6 h-6 text-purple-400 mx-auto" />
          </div>
          <div className="text-sm font-medium">75%</div>
          <div className="text-xs text-gray-400">Humedad</div>
        </div>
        <div className="text-center">
          <div className="bg-white/5 p-3 rounded-lg mb-2">
            <Cloud className="w-6 h-6 text-orange-400 mx-auto" />
          </div>
          <div className="text-sm font-medium">90%</div>
          <div className="text-xs text-gray-400">Nubes</div>
        </div>
      </div>

      <div className="mt-6 pt-6 border-t border-white/10">
        <div className="flex justify-between items-center text-sm">
          <div className="space-y-4">
            {[
              { time: '15:00', temp: '19°', icon: <CloudRain className="w-5 h-5 text-blue-400" /> },
              { time: '18:00', temp: '17°', icon: <Cloud className="w-5 h-5 text-gray-400" /> },
              { time: '21:00', temp: '15°', icon: <Cloud className="w-5 h-5 text-gray-400" /> },
            ].map((hour) => (
              <div key={hour.time} className="flex items-center space-x-3">
                <span className="w-12 text-gray-400">{hour.time}</span>
                {hour.icon}
                <span className="font-medium">{hour.temp}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};