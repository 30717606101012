import React from 'react';
import { X, FileText, Phone, MapPin, Shield, Download } from 'lucide-react';

interface ResourcesPanelProps {
  onClose: () => void;
}

export const ResourcesPanel: React.FC<ResourcesPanelProps> = ({ onClose }) => {
  const resources = [
    {
      title: 'Guía de Emergencias',
      description: 'Manual completo de actuación ante inundaciones',
      icon: <FileText className="w-6 h-6" />,
      link: '#'
    },
    {
      title: 'Contactos de Emergencia',
      description: 'Directorio de teléfonos y contactos importantes',
      icon: <Phone className="w-6 h-6" />,
      link: '#'
    },
    {
      title: 'Puntos de Evacuación',
      description: 'Mapa de puntos seguros y rutas de evacuación',
      icon: <MapPin className="w-6 h-6" />,
      link: '#'
    },
    {
      title: 'Plan de Contingencia',
      description: 'Protocolo oficial de actuación',
      icon: <Shield className="w-6 h-6" />,
      link: '#'
    }
  ];

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" onClick={onClose} />
      
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-gradient-to-br from-gray-900/90 to-blue-900/90 backdrop-blur-xl rounded-3xl w-full max-w-4xl p-8">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Recursos y Documentación
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {resources.map((resource) => (
              <a
                key={resource.title}
                href={resource.link}
                className="group glass-card p-6 hover:border-blue-500/30 transition-all duration-300"
              >
                <div className="flex items-start space-x-4">
                  <div className="bg-gradient-to-br from-blue-500/20 to-purple-500/20 p-3 rounded-xl">
                    {resource.icon}
                  </div>
                  <div className="flex-1">
                    <h3 className="font-semibold text-lg text-white mb-2">
                      {resource.title}
                    </h3>
                    <p className="text-sm text-gray-400 mb-4">
                      {resource.description}
                    </p>
                    <div className="flex items-center text-blue-400 text-sm">
                      <Download className="w-4 h-4 mr-2" />
                      <span>Descargar PDF</span>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>

          <div className="mt-8 pt-8 border-t border-white/10">
            <h3 className="text-lg font-semibold mb-4">Recursos Adicionales</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {['Mapas de Riesgo', 'Protocolos', 'Formación'].map((item) => (
                <button
                  key={item}
                  className="px-4 py-2 bg-white/5 rounded-lg hover:bg-white/10 transition-colors text-sm"
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};