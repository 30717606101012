import React from 'react';
import { Bell, Settings, User, LogOut } from 'lucide-react';
import { useAdminStore } from '../../stores/adminStore';

export const AdminHeader: React.FC = () => {
  const { notifications, user, logout } = useAdminStore();

  return (
    <header className="bg-gray-900/90 border-b border-white/10 px-6 py-4">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
          Panel de Administración
        </h1>
        
        <div className="flex items-center space-x-4">
          <button className="relative p-2 hover:bg-white/5 rounded-lg transition-colors">
            <Bell className="w-5 h-5" />
            {notifications.length > 0 && (
              <span className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full text-xs flex items-center justify-center">
                {notifications.length}
              </span>
            )}
          </button>

          <button className="p-2 hover:bg-white/5 rounded-lg transition-colors">
            <Settings className="w-5 h-5" />
          </button>

          <div className="flex items-center space-x-3 border-l border-white/10 pl-4">
            <div className="text-right">
              <div className="font-medium">{user?.name}</div>
              <div className="text-sm text-gray-400">{user?.role}</div>
            </div>
            <button 
              onClick={logout}
              className="p-2 hover:bg-white/5 rounded-lg transition-colors text-red-400"
            >
              <LogOut className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};