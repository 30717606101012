import React from 'react';
import { Twitter, Facebook, Instagram, Linkedin, Mail, Heart, Shield, FileText } from 'lucide-react';

export const Footer: React.FC = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-900/90 to-purple-900/90 backdrop-blur-xl border-t border-white/10 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Karealerts</h4>
            <p className="text-sm text-gray-400 mb-4">
              Sistema de Alertas Inteligente
            </p>
            <div className="flex space-x-4">
              <a href="https://twitter.com/karealerts" className="text-gray-400 hover:text-white transition-colors">
                <Twitter className="w-5 h-5" />
              </a>
              <a href="https://facebook.com/karealerts" className="text-gray-400 hover:text-white transition-colors">
                <Facebook className="w-5 h-5" />
              </a>
              <a href="https://linkedin.com/company/karealerts" className="text-gray-400 hover:text-white transition-colors">
                <Linkedin className="w-5 h-5" />
              </a>
              <a href="mailto:info@karealerts.com" className="text-gray-400 hover:text-white transition-colors">
                <Mail className="w-5 h-5" />
              </a>
            </div>
          </div>

          <div>
            <h5 className="font-semibold text-white mb-4">Legal</h5>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="/privacidad" className="text-gray-400 hover:text-white transition-colors flex items-center gap-2">
                  <Shield className="w-4 h-4" />
                  Política de Privacidad
                </a>
              </li>
              <li>
                <a href="/cookies" className="text-gray-400 hover:text-white transition-colors flex items-center gap-2">
                  <FileText className="w-4 h-4" />
                  Política de Cookies
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h5 className="font-semibold text-white mb-4">Contacto</h5>
            <p className="text-sm text-gray-400">
              Email: info@karealerts.com
            </p>
          </div>
        </div>

        <div className="border-t border-white/10 pt-8">
          <div className="text-sm text-gray-400">
            <p>
              La información proporcionada por Karealerts tiene carácter informativo y no constituye 
              asesoramiento oficial. Para decisiones críticas, consulte siempre con las autoridades competentes.
            </p>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mt-8 pt-8 border-t border-white/10">
            <div className="text-sm text-gray-400">
              © {new Date().getFullYear()} Karealerts. Todos los derechos reservados.
            </div>
            <div className="flex items-center gap-2 text-sm text-gray-400">
              <span>Desarrollado en España con</span>
              <Heart className="w-4 h-4 text-red-400" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};