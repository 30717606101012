import React from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAdminStore } from '../../stores/adminStore';

export const AdminNotifications: React.FC = () => {
  const { notifications, removeNotification } = useAdminStore();

  return (
    <div className="fixed bottom-6 right-6 space-y-4 z-50">
      <AnimatePresence>
        {notifications.map((notification) => (
          <motion.div
            key={notification.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={`glass-card p-4 min-w-[300px] ${
              notification.type === 'error' ? 'bg-red-500/20' :
              notification.type === 'warning' ? 'bg-yellow-500/20' :
              'bg-blue-500/20'
            }`}
          >
            <div className="flex items-start justify-between">
              <div>
                <div className="font-medium">{notification.message}</div>
                <div className="text-sm text-gray-400">
                  {notification.timestamp.toLocaleTimeString()}
                </div>
              </div>
              <button
                onClick={() => removeNotification(notification.id)}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};