import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import MainLayout from './layouts/MainLayout';
import AdminLayout from './layouts/AdminLayout';
import HomePage from './pages/HomePage';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminUsers from './pages/admin/AdminUsers';
import AdminAlerts from './pages/admin/AdminAlerts';
import AdminSettings from './pages/admin/AdminSettings';
import LoginPage from './pages/admin/LoginPage';
import { useAdminStore } from './stores/adminStore';

const App: React.FC = () => {
  const isAuthenticated = useAdminStore(state => state.isAuthenticated);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          {/* Main Routes */}
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomePage />} />
          </Route>

          {/* Admin Routes */}
          <Route path="/admin" element={<AdminLayout />}>
            {isAuthenticated ? (
              <>
                <Route index element={<AdminDashboard />} />
                <Route path="users" element={<AdminUsers />} />
                <Route path="alerts" element={<AdminAlerts />} />
                <Route path="settings" element={<AdminSettings />} />
              </>
            ) : (
              <Route index element={<LoginPage />} />
            )}
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster />
    </HelmetProvider>
  );
};

export default App;