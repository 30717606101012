import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const MainLayout: React.FC = () => {
  return (
    <div className="min-h-screen bg-background text-white">
      <Header 
        onShowResources={() => {}}
        onShowMonitoring={() => {}}
        onShowAdmin={() => {}}
      />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayout;