import React from 'react';
import { AlertTriangle, MapPin, Clock, MoreVertical } from 'lucide-react';
import { AdminAlert } from '../../../types/admin';

interface AlertListProps {
  alerts: AdminAlert[];
  loading: boolean;
}

export const AlertList: React.FC<AlertListProps> = ({ alerts, loading }) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="glass-card overflow-hidden">
      <table className="w-full">
        <thead>
          <tr className="border-b border-white/10">
            <th className="text-left p-4 font-medium text-gray-400">Alerta</th>
            <th className="text-left p-4 font-medium text-gray-400">Tipo</th>
            <th className="text-left p-4 font-medium text-gray-400">Ubicación</th>
            <th className="text-left p-4 font-medium text-gray-400">Estado</th>
            <th className="text-left p-4 font-medium text-gray-400">Fecha</th>
            <th className="p-4"></th>
          </tr>
        </thead>
        <tbody>
          {alerts.map((alert) => (
            <tr key={alert.id} className="border-b border-white/5 hover:bg-white/5">
              <td className="p-4">
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-lg ${
                    alert.severity === 'high' ? 'bg-red-500/20' :
                    alert.severity === 'medium' ? 'bg-yellow-500/20' :
                    'bg-blue-500/20'
                  }`}>
                    <AlertTriangle className={`w-5 h-5 ${
                      alert.severity === 'high' ? 'text-red-400' :
                      alert.severity === 'medium' ? 'text-yellow-400' :
                      'text-blue-400'
                    }`} />
                  </div>
                  <div>
                    <div className="font-medium">{alert.title}</div>
                    <div className="text-sm text-gray-400">{alert.description}</div>
                  </div>
                </div>
              </td>
              <td className="p-4">
                <span className="px-2 py-1 rounded-full text-xs bg-white/10">
                  {alert.type}
                </span>
              </td>
              <td className="p-4">
                <div className="flex items-center space-x-2">
                  <MapPin className="w-4 h-4 text-gray-400" />
                  <span>{alert.location}</span>
                </div>
              </td>
              <td className="p-4">
                <span className={`px-2 py-1 rounded-full text-xs ${
                  alert.status === 'active' ? 'bg-green-500/20 text-green-400' :
                  alert.status === 'resolved' ? 'bg-blue-500/20 text-blue-400' :
                  'bg-red-500/20 text-red-400'
                }`}>
                  {alert.status.toUpperCase()}
                </span>
              </td>
              <td className="p-4">
                <div className="flex items-center space-x-2 text-gray-400">
                  <Clock className="w-4 h-4" />
                  <span>{new Date(alert.timestamp).toLocaleString()}</span>
                </div>
              </td>
              <td className="p-4">
                <button className="p-2 hover:bg-white/10 rounded-lg transition-colors">
                  <MoreVertical className="w-4 h-4" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};