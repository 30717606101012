import React from 'react';
import { Helmet } from 'react-helmet-async';
import { LoginForm } from '../../components/admin/auth/LoginForm';

const LoginPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Iniciar Sesión - Karealerts Admin</title>
      </Helmet>

      <div className="min-h-screen bg-background flex items-center justify-center p-4">
        <LoginForm />
      </div>
    </>
  );
};

export default LoginPage;