import React from 'react';
import { 
  Home, Users, AlertTriangle, Map, Settings, 
  BarChart2, Shield, Database, MessageSquare, Award
} from 'lucide-react';
import { useAdminStore } from '../../stores/adminStore';

export const AdminSidebar: React.FC = () => {
  const { currentSection, setCurrentSection } = useAdminStore();

  const sections = [
    { id: 'dashboard', name: 'Dashboard', icon: Home },
    { id: 'users', name: 'Usuarios', icon: Users },
    { id: 'alerts', name: 'Alertas', icon: AlertTriangle },
    { id: 'regions', name: 'Regiones', icon: Map },
    { id: 'analytics', name: 'Analíticas', icon: BarChart2 },
    { id: 'security', name: 'Seguridad', icon: Shield },
    { id: 'data', name: 'Datos', icon: Database },
    { id: 'community', name: 'Comunidad', icon: MessageSquare },
    { id: 'rewards', name: 'Recompensas', icon: Award },
    { id: 'settings', name: 'Configuración', icon: Settings }
  ];

  return (
    <aside className="w-64 bg-gray-900/90 border-r border-white/10 min-h-screen p-4">
      <nav className="space-y-2">
        {sections.map(({ id, name, icon: Icon }) => (
          <button
            key={id}
            onClick={() => setCurrentSection(id)}
            className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors ${
              currentSection === id
                ? 'bg-blue-500/20 text-blue-400'
                : 'text-gray-400 hover:bg-white/5'
            }`}
          >
            <Icon className="w-5 h-5" />
            <span>{name}</span>
          </button>
        ))}
      </nav>
    </aside>
  );
};