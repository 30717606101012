import React, { useState } from 'react';
import { Search, MapPin, Loader2 } from 'lucide-react';

interface SearchBarProps {
  onSearch: (query: string) => void;
  onLocationSelect?: (coords: { lat: number; lng: number }) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ onSearch, onLocationSelect }) => {
  const [query, setQuery] = useState('');
  const [isLocating, setIsLocating] = useState(false);
  const [locationError, setLocationError] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(query);
  };

  const getCurrentLocation = () => {
    setIsLocating(true);
    setLocationError('');

    if (!navigator.geolocation) {
      setLocationError('Tu navegador no soporta geolocalización');
      setIsLocating(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        onLocationSelect?.({ lat: latitude, lng: longitude });
        setIsLocating(false);
      },
      (error) => {
        console.error('Error getting location:', error);
        setLocationError('No se pudo obtener tu ubicación');
        setIsLocating(false);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  };

  return (
    <div className="relative">
      <form onSubmit={handleSubmit} className="relative">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="block w-full pl-12 pr-32 py-3 bg-white/5 border border-white/10 rounded-xl text-white placeholder-gray-400 focus:outline-none focus:border-blue-500/50 focus:ring-1 focus:ring-blue-500/50 transition-all duration-200"
            placeholder="Buscar ubicación o alerta..."
          />
        </div>
      </form>

      <button
        onClick={getCurrentLocation}
        disabled={isLocating}
        className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors disabled:opacity-50"
      >
        {isLocating ? (
          <>
            <Loader2 className="h-5 w-5 animate-spin" />
            <span className="text-sm">Localizando...</span>
          </>
        ) : (
          <>
            <MapPin className="h-5 w-5" />
            <span className="text-sm">Usar ubicación actual</span>
          </>
        )}
      </button>

      {locationError && (
        <div className="absolute top-full left-0 right-0 mt-2 p-3 bg-red-500/20 border border-red-500/20 rounded-lg text-sm text-red-400">
          {locationError}
        </div>
      )}
    </div>
  );
};