import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { getDistance } from 'geolocation-utils';

interface Location {
  latitude: number;
  longitude: number;
  accuracy: number;
  timestamp: number;
  speed?: number;
  heading?: number;
}

interface GeolocationHook {
  location: Location | null;
  error: string | null;
  loading: boolean;
  permissionDenied: boolean;
  requestPermission: () => Promise<void>;
  watchLocation: () => void;
  stopWatching: () => void;
  getDistanceFromLocation: (lat: number, lon: number) => number | null;
  isWithinRadius: (lat: number, lon: number, radius: number) => boolean;
}

export function useGeolocation(): GeolocationHook {
  const [location, setLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [watchId, setWatchId] = useState<number | null>(null);

  const handleSuccess = (position: GeolocationPosition) => {
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
      timestamp: position.timestamp,
      speed: position.coords.speed || undefined,
      heading: position.coords.heading || undefined
    });
    setLoading(false);
    setError(null);
    setPermissionDenied(false);
  };

  const handleError = (error: GeolocationPositionError) => {
    setLoading(false);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setPermissionDenied(true);
        setError('Por favor, activa la ubicación en tu navegador para una mejor experiencia');
        toast.error('Acceso a ubicación denegado');
        break;
      case error.POSITION_UNAVAILABLE:
        setError('No se pudo obtener tu ubicación. Verifica tu conexión GPS');
        toast.error('Ubicación no disponible');
        break;
      case error.TIMEOUT:
        setError('Se agotó el tiempo para obtener tu ubicación');
        toast.error('Tiempo de espera agotado');
        break;
      default:
        setError('Error desconocido al obtener la ubicación');
        toast.error('Error de geolocalización');
    }
  };

  const requestPermission = useCallback(async () => {
    if (!navigator.geolocation) {
      setError('Tu navegador no soporta geolocalización');
      toast.error('Geolocalización no soportada');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const permission = await navigator.permissions.query({ name: 'geolocation' });
      
      if (permission.state === 'denied') {
        setPermissionDenied(true);
        setError('Por favor, habilita la ubicación en la configuración de tu navegador');
        setLoading(false);
        return;
      }

      navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      });
    } catch (error) {
      setError('Error al solicitar permisos de ubicación');
      setLoading(false);
    }
  }, []);

  const watchLocation = useCallback(() => {
    if (!navigator.geolocation) {
      setError('Tu navegador no soporta geolocalización');
      return;
    }

    const id = navigator.geolocation.watchPosition(handleSuccess, handleError, {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0
    });

    setWatchId(id);
  }, []);

  const stopWatching = useCallback(() => {
    if (watchId !== null) {
      navigator.geolocation.clearWatch(watchId);
      setWatchId(null);
    }
  }, [watchId]);

  const getDistanceFromLocation = useCallback((lat: number, lon: number): number | null => {
    if (!location) return null;

    return getDistance(
      { lat: location.latitude, lon: location.longitude },
      { lat, lon }
    );
  }, [location]);

  const isWithinRadius = useCallback((lat: number, lon: number, radius: number): boolean => {
    const distance = getDistanceFromLocation(lat, lon);
    if (distance === null) return false;
    return distance <= radius;
  }, [getDistanceFromLocation]);

  useEffect(() => {
    requestPermission();
    return () => {
      if (watchId !== null) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  return {
    location,
    error,
    loading,
    permissionDenied,
    requestPermission,
    watchLocation,
    stopWatching,
    getDistanceFromLocation,
    isWithinRadius
  };
}