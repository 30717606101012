import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Users, AlertTriangle, Map, Activity } from 'lucide-react';
import { AdminMetricCard } from '../../components/admin/AdminMetricCard';
import { AdminChart } from '../../components/admin/AdminChart';
import { useAdminStats } from '../../hooks/useAdminStats';

const AdminDashboard: React.FC = () => {
  const { stats, loading } = useAdminStats();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Panel de Control - Karealerts Admin</title>
      </Helmet>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <AdminMetricCard
            title="Usuarios Activos"
            value={stats.activeUsers}
            change={stats.userChange}
            icon={<Users className="w-6 h-6 text-blue-400" />}
          />
          <AdminMetricCard
            title="Alertas Activas"
            value={stats.activeAlerts}
            change={stats.alertChange}
            icon={<AlertTriangle className="w-6 h-6 text-red-400" />}
          />
          <AdminMetricCard
            title="Regiones Monitorizadas"
            value={stats.monitoredRegions}
            change={stats.regionChange}
            icon={<Map className="w-6 h-6 text-green-400" />}
          />
          <AdminMetricCard
            title="Tiempo de Respuesta"
            value={`${stats.responseTime}ms`}
            change={stats.responseTimeChange}
            icon={<Activity className="w-6 h-6 text-purple-400" />}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <AdminChart
            title="Actividad del Sistema"
            data={stats.systemActivity}
            type="line"
          />
          <AdminChart
            title="Distribución de Alertas"
            data={stats.alertDistribution}
            type="pie"
          />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;