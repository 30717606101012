import React, { useState } from 'react';
import { AlertTriangle, Send, Camera, MapPin, Loader2 } from 'lucide-react';

interface ReportIncidentProps {
  onSubmit: (report: any) => void;
}

export const ReportIncident: React.FC<ReportIncidentProps> = ({ onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    type: '',
    description: '',
    location: '',
    image: null as File | null,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1500));
    onSubmit(form);
    setLoading(false);
    setIsOpen(false);
    setForm({ type: '', description: '', location: '', image: null });
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      {!isOpen ? (
        <button
          onClick={() => setIsOpen(true)}
          className="glass-button flex items-center space-x-2"
        >
          <AlertTriangle className="w-5 h-5" />
          <span>Reportar Incidencia</span>
        </button>
      ) : (
        <div className="glass-card w-96 p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Reportar Incidencia
            </h3>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-400 hover:text-white"
            >
              ✕
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <select
              value={form.type}
              onChange={(e) => setForm({ ...form, type: e.target.value })}
              required
              className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 text-white focus:outline-none focus:border-blue-500/50"
            >
              <option value="">Tipo de Incidencia</option>
              <option value="flood">Inundación</option>
              <option value="rain">Lluvia Intensa</option>
              <option value="damage">Daños Estructurales</option>
              <option value="help">Necesito Ayuda</option>
            </select>

            <div className="relative">
              <textarea
                value={form.description}
                onChange={(e) => setForm({ ...form, description: e.target.value })}
                placeholder="Describe la situación..."
                required
                className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500/50 min-h-[100px]"
              />
            </div>

            <div className="relative">
              <div className="flex items-center space-x-2 bg-white/5 border border-white/10 rounded-xl px-4 py-3">
                <MapPin className="w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  value={form.location}
                  onChange={(e) => setForm({ ...form, location: e.target.value })}
                  placeholder="Ubicación"
                  required
                  className="bg-transparent w-full text-white placeholder-gray-400 focus:outline-none"
                />
              </div>
            </div>

            <div className="relative">
              <label className="block w-full cursor-pointer">
                <div className="flex items-center justify-center space-x-2 bg-white/5 border border-dashed border-white/10 rounded-xl px-4 py-6 text-gray-400 hover:border-blue-500/50 transition-colors">
                  <Camera className="w-5 h-5" />
                  <span>{form.image ? form.image.name : 'Añadir foto (opcional)'}</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => setForm({ ...form, image: e.target.files?.[0] || null })}
                />
              </label>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full glass-button disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
            >
              {loading ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span>Enviando...</span>
                </>
              ) : (
                <>
                  <Send className="w-4 h-4" />
                  <span>Enviar Reporte</span>
                </>
              )}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};