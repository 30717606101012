import React from 'react';
import { Region } from '../types';
import { CloudRain, Wind, Thermometer, Droplets } from 'lucide-react';
import { motion } from 'framer-motion';

interface StatsGridProps {
  regions: Region[];
}

const formatNumber = (value: number | undefined): string => {
  if (typeof value !== 'number') return '0';
  return Math.round(value).toString();
};

export const StatsGrid: React.FC<StatsGridProps> = ({ regions }) => {
  const getAverages = () => {
    if (!regions.length) return { temp: 0, rain: 0, wind: 0, humidity: 0 };
    
    return regions.reduce((acc, region) => ({
      temp: acc.temp + (region.weather?.temperature || 0),
      rain: acc.rain + region.rainfall,
      wind: acc.wind + (region.weather?.windSpeed || 0),
      humidity: acc.humidity + (region.weather?.humidity || 0)
    }), { temp: 0, rain: 0, wind: 0, humidity: 0 });
  };

  const averages = getAverages();
  const count = Math.max(regions.length, 1);

  const stats = [
    {
      title: 'Temperatura Media',
      value: `${formatNumber(averages.temp / count)}°C`,
      icon: <Thermometer className="w-6 h-6 text-orange-400" />,
      bgColor: 'from-orange-500/20 to-red-500/20'
    },
    {
      title: 'Precipitación',
      value: `${formatNumber(averages.rain / count)} mm/h`,
      icon: <CloudRain className="w-6 h-6 text-blue-400" />,
      bgColor: 'from-blue-500/20 to-purple-500/20'
    },
    {
      title: 'Velocidad del Viento',
      value: `${formatNumber(averages.wind / count)} km/h`,
      icon: <Wind className="w-6 h-6 text-green-400" />,
      bgColor: 'from-green-500/20 to-teal-500/20'
    },
    {
      title: 'Humedad',
      value: `${formatNumber(averages.humidity / count)}%`,
      icon: <Droplets className="w-6 h-6 text-purple-400" />,
      bgColor: 'from-purple-500/20 to-pink-500/20'
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {stats.map((stat, index) => (
        <motion.div
          key={stat.title}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="glass-card p-6 hover:scale-[1.02] transition-all duration-300"
        >
          <div className="flex items-start justify-between">
            <div>
              <p className="text-sm text-gray-400 mb-1">{stat.title}</p>
              <p className="text-2xl font-bold">{stat.value}</p>
            </div>
            <div className={`p-3 rounded-lg bg-gradient-to-br ${stat.bgColor}`}>
              {stat.icon}
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default StatsGrid;