import React from 'react';
import { X } from 'lucide-react';

export const AlertFilters: React.FC = () => {
  return (
    <div className="glass-card p-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="font-medium">Filtros</h3>
        <button className="text-gray-400 hover:text-white">
          <X className="w-4 h-4" />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div>
          <label className="block text-sm text-gray-400 mb-2">Tipo</label>
          <select className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 text-white">
            <option value="">Todos los tipos</option>
            <option value="weather">Meteorológica</option>
            <option value="flood">Inundación</option>
            <option value="transport">Transporte</option>
            <option value="infrastructure">Infraestructura</option>
          </select>
        </div>

        <div>
          <label className="block text-sm text-gray-400 mb-2">Severidad</label>
          <select className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 text-white">
            <option value="">Todas las severidades</option>
            <option value="high">Alta</option>
            <option value="medium">Media</option>
            <option value="low">Baja</option>
          </select>
        </div>

        <div>
          <label className="block text-sm text-gray-400 mb-2">Estado</label>
          <select className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 text-white">
            <option value="">Todos los estados</option>
            <option value="active">Activa</option>
            <option value="resolved">Resuelta</option>
            <option value="expired">Expirada</option>
          </select>
        </div>

        <div>
          <label className="block text-sm text-gray-400 mb-2">Fecha</label>
          <select className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 text-white">
            <option value="">Todas las fechas</option>
            <option value="today">Hoy</option>
            <option value="week">Última semana</option>
            <option value="month">Último mes</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <button className="px-4 py-2 text-gray-400 hover:text-white transition-colors">
          Limpiar
        </button>
        <button className="glass-button">
          Aplicar Filtros
        </button>
      </div>
    </div>
  );
};