import React, { useState } from 'react';
import { Plus, Search, Filter } from 'lucide-react';
import { AlertList } from './AlertList';
import { AlertFilters } from './AlertFilters';
import { CreateAlertModal } from './CreateAlertModal';
import { useAlerts } from '../../../hooks/useAlerts';

export const AlertManagement: React.FC = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { alerts, loading } = useAlerts();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Gestión de Alertas</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          className="glass-button flex items-center space-x-2"
        >
          <Plus className="w-4 h-4" />
          <span>Nueva Alerta</span>
        </button>
      </div>

      <div className="flex items-center space-x-4">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Buscar alertas..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full bg-white/5 border border-white/10 rounded-xl pl-10 pr-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500/50"
          />
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="p-2 hover:bg-white/5 rounded-lg transition-colors"
        >
          <Filter className="w-5 h-5" />
        </button>
      </div>

      {showFilters && <AlertFilters />}

      <AlertList alerts={alerts} loading={loading} />
      
      {showCreateModal && (
        <CreateAlertModal onClose={() => setShowCreateModal(false)} />
      )}
    </div>
  );
};